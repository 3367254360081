<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon v-if="model=='App\\News'">article</md-icon>
                    <md-icon v-if="model=='App\\Notice'">sticky_note_2</md-icon>
                    <md-icon v-if="model=='App\\Ad'">view_kanban</md-icon>
                </div>
                <h4 class="title">
                    <span v-if="model=='App\\News'">{{ trans('app.news_item') }}</span>
                    <span v-if="model=='App\\Notice'">{{ trans('app.notices_item') }}</span>
                    <span v-if="model=='App\\Ad'">{{ trans('app.ads_item') }}</span>
                    <md-progress-spinner v-if="form.loading" :md-stroke="1" :md-diameter="20" md-mode="indeterminate"></md-progress-spinner>
                </h4>
            </md-card-header>
            <md-card-content>
                <form class="md-layout" v-on:submit.prevent>
                    <div class="md-layout-item md-size-100">
                        <div class="md-layout">
                            <div v-if="hasImage" class="md-layout-item md-size-50 md-small-size-100">
                                <regular-image ref='image' @change="onFileSelection" :model='form' field='image' :img='form.image' height='80px'></regular-image>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100" :class="{'md-size-100': !hasImage}">
                                <div class="md-layout">
                                    <div class="md-layout-item md-size-50 md-small-size-100" :class="{'md-size-100': hasImage}">
                                        <md-field :class="form.getFieldClass('date')">
                                            <label for="date">{{ trans('fields.date')+" (dd/mm/aaaa)" }}</label>
                                            <md-input id="date" v-model="form.date" />
                                            <has-error :form="form" field="date" />
                                        </md-field>
                                    </div>
                                    <div class="md-layout-item md-size-50 md-small-size-100" :class="{'md-size-100': hasImage}">
                                        <md-field :class="form.getFieldClass('hour')">
                                            <label for="hour">{{ trans('fields.hour')+" (HH:mm)" }}</label>
                                            <md-input id="hour" v-model="form.hour" />
                                            <has-error :form="form" field="hour" />
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('title')">
                            <label for="title">{{ trans('fields.title') }}</label>
                            <md-input id="title" v-model="form.title" />
                            <has-error :form="form" field="title" />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('description')">
                            <label for="description">{{ trans('fields.description') }}</label>
                            <md-textarea id="description" v-model="form.description" />
                            <has-error :form="form" field="description" />
                        </md-field>
                    </div>
                    <div v-if="hasCategory" class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('category_code')">
                            <label for="category_code">{{ trans('fields.category_code') }}</label>
                            <md-select id='category_code' v-model='form.category_code' style="overflow: hidden;">
                                <md-option v-for="(category, index) in categories" :key='index' :value='category.code'>{{ category.description }}</md-option>
                            </md-select>
                            <has-error :form="form" field="category_code" />
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <div class="rich-text-editor">
                            <label for="content">{{ trans('fields.content') }}</label>
                            <ckeditor id="content" v-model="form.content" :editor="editor" :config="editorConfig"></ckeditor>
                            <has-error :form="form" field="content" />
                        </div>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <label for="roles">{{ modelParams.modelRoles }}</label>
                    </div>
                    <div class="md-layout-item md-size-30 md-small-size-100">
                        <md-checkbox v-model="form.public" :value="true" @change="checkPublic">{{ trans('app.public_roles') }}</md-checkbox>
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-checkbox v-for="(role, index) in roles" :key='index' v-model="selectedRoles" :value='role.id' @change="checkHasRoles" style="min-width: 200px">{{ role.description }}</md-checkbox>
                    </div>
                    <!--
                    <div class="md-layout-item md-size-80 md-small-size-100">
                        <md-field :class="form.getFieldClass('roles')">
                            <label for="roles">{{ trans('fields.roles') }}</label>
                            <md-select id='roles' v-model='form.roles' multiple :disabled="form.public" style="overflow: hidden;">
                                <md-option v-for="(role, index) in roles" :key='index' :value='role.id' >{{ role.description }}</md-option>
                            </md-select>
                            <has-error :form="form" field="roles"/>
                        </md-field>
                    </div>
                    -->
                    <div v-if="hasHighlight" class="md-layout-item md-size-100">
                        <label for="highlight">{{ trans('fields.highlight') }}</label>
                    </div>
                    <div v-if="hasHighlight" class="md-layout-item md-size-100">
                        <md-radio v-model="form.highlight" :value="true" class="md-primary">{{ trans('common.yes') }}</md-radio>
                        <md-radio v-model="form.highlight" :value="false" class="md-primary">{{ trans('common.no') }}</md-radio>
                        <has-error :form="form" field="highlight" />
                    </div>
                    <div v-if="form.highlight" class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('highlight_end_date')">
                            <label for="highlight_end_date">{{ trans('fields.highlight_end_date')+" (dd/mm/aaaa)" }}</label>
                            <md-input id="highlight_end_date" v-model="form.highlight_end_date" />
                            <has-error :form="form" field="highlight_end_date" />
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <label for="pinned">{{ trans('fields.pinned') }}</label>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-radio v-model="form.pinned" :value="true" class="md-primary">{{ trans('common.yes') }}</md-radio>
                        <md-radio v-model="form.pinned" :value="false" class="md-primary">{{ trans('common.no') }}</md-radio>
                        <has-error :form="form" field="pinned"/>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <label for="notification">{{ trans('app.send_notification') }}</label>
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-radio v-model="form.notification" :value="true" class="md-primary">{{ trans('common.yes') }}</md-radio>
                        <md-radio v-model="form.notification" :value="false" class="md-primary">{{ trans('common.no') }}</md-radio>
                        <has-error :form="form" field="notification" />
                    </div>
                    <div v-if="form.notification" class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('notification_type_code')">
                            <label for="notification_type_code">{{ trans('fields.notification_type_code') }}</label>
                            <md-select id='notification_type_code' v-model='form.notification_type_code' style="overflow: hidden;">
                                <md-option v-for="(type, index) in types" :key='index' :value='type.code'>{{ type.description }}</md-option>
                            </md-select>
                            <has-error :form="form" field="notification_type_code" />
                        </md-field>
                    </div>
                    <!-- <div v-if="form.notification && (form.notification_type_code=='email' || form.notification_type_code=='both')" class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('notification_url')">
                            <label for="notification_url">{{ trans('fields.notification_url') }}</label>
                            <md-input id="notification_url" v-model="form.notification_url" />
                            <has-error :form="form" field="notification_url" />
                        </md-field>
                    </div> -->
                    <div class="md-layout-item md-size-100 text-right">
                        <md-button class="md-primary" @click="add()" :disabled="form.loading" id="btnSave">
                            <span v-show="form.loading">{{ trans('common.saving') }}</span>
                            <span v-show="!form.loading">{{ trans('common.save') }}</span>
                        </md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import { api } from "../../../config";
import Form from "../../../plugins/Form/Form";
import file from "../../../mixins/file";
import RegularImage from "../../Inputs/RegularImage.vue";
import ClassicEditor from '@fiveflames/ckeditor5-4rayas-build';
import moment from 'moment';

export default {
    mixins: [
        file,
    ],

    components: {
        RegularImage
    },

    mounted() {
        this.fetchData();
        this.specificRequiredFields();
    },

    props: {
        model: {
            type: String,
            required: true,
        }
    },

    computed: {
        hasImage: function() {
            return (this.model == 'App\\News' || this.model == 'App\\Ad');
        },

        hasCategory: function() {
            return (this.model == 'App\\News');
        },

        hasHighlight: function() {
            return (this.model == 'App\\News' || this.model == 'App\\Ad');
        },

        modelParams: function() {
            let routeAddLink = '';
            let fetchLink = '';
            let modelTranslated = '';
            let modelName = '';
            let modelRoles = '';
            switch (this.model) {
                case 'App\\News':
                    routeAddLink = 'newsItem';
                    fetchLink = 'news-list';
                    modelTranslated = this.trans('app.news');
                    modelName = 'news';
                    modelRoles = this.trans('app.news_roles');
                    break;
                case 'App\\Notice':
                    routeAddLink = 'noticesItem';
                    fetchLink = 'notices-list';
                    modelTranslated = this.trans('app.notices');
                    modelName = 'notices';
                    modelRoles = this.trans('app.notices_roles');
                    break;
                case 'App\\Ad':
                    routeAddLink = 'adsItem';
                    fetchLink = 'ads-list';
                    modelTranslated = this.trans('app.ads');
                    modelName = 'ads';
                    modelRoles = this.trans('app.ads_roles');
                    break;
            }

            return {
                routerAddLink: routeAddLink,
                fetchLink: fetchLink,
                modelTranslated: modelTranslated,
                modelName: modelName,
                modelRoles: modelRoles,
            };
        }
    },

    data() {
        return {
            requiredFields: [
                "title",
                "description",
                "content",
            ],
            selectedRoles: [],
            form: new Form(),
            categories: [],
            roles: [],
            types: [],
            editor: ClassicEditor,
            editorConfig: {
                language: 'es',
                simpleUpload: {
                    // The URL that the images are uploaded to.
                    uploadUrl: window.Laravel.siteUrl + '/ckeditor/upload',

                    // Enable the XMLHttpRequest.withCredentials property.
                    withCredentials: true,

                    // Headers sent along with the XMLHttpRequest to the upload server.
                    headers: {
                        'X-CSRF-TOKEN': window.Laravel.csrfToken,
                        //Authorization: 'Bearer <JSON Web Token>'
                    }
                },
                resize_enabled: true,
                resize_dir: 'vertical',
                resize_minHeight: 300
            }
        };
    },

    methods: {
        specificRequiredFields() {
            if (this.model == 'App\\News') {
                this.requiredFields.push('category_code');
            }
        },

        checkPublic(value) {
            if (value === true) {
                this.selectedRoles = [];
            }
        },

        checkHasRoles(value) {
            if (this.selectedRoles.length > 0) {
                this.form.public = false;
            }
        },

        fetchData() {
            var url = api.domain + this.modelParams.modelName + "/";
            if (this.$route.params.id) {
                url += this.$route.params.id + "/edit";
            } else {
                url += "create";
            }
            axios.get(url).then(response => {
                this.form = new Form(response.data[this.modelParams.modelName]);

                // Asked to populate date and hour fields even if datetime is not informed (not scheduled)
                // Do it here has I don't want to change the behaviour of the model iteself
                if(this.form.title) { // Only do it if editing, not if creating new
                    if(!this.form.date) {
                        this.form.date = moment(this.form.createddate).format('DD/MM/YYYY');
                    }
                    if(!this.form.hour) {
                        this.form.hour = moment(this.form.createddate).format('HH:mm');
                    }
                } else {
                    this.form.pinned = false; // If creating new, set default value
                }

                if (response.data[this.modelParams.modelName].roles) {
                    this.selectedRoles = response.data[this.modelParams.modelName].roles.map((element) => element.id);
                }
                this.form.requiredFields = this.requiredFields;
                if (response.data.categories) this.categories = response.data.categories;
                this.roles = response.data.roles;
                if (response.data.types) this.types = response.data.types;
            }).catch(function(error) {
                console.log("An error occured obtaining item info" + error);
            });
        },

        async add() {
            var response;

            this.form.roles = this.selectedRoles; // Debo usar este modelo intermedio porque si uso
            // directamente form.roles los checkboxes con v-for no fonan
            // (sí fona si lo hago con select con v-for...)

            try {
                if (this.form.id != null && this.form.id != '') {
                    // Edition
                    response = await this.form.put(api.domain + this.modelParams.modelName + "/" + this.form.id);
                } else {
                    // Creation
                    response = await this.form.post(api.domain + this.modelParams.modelName);
                }

                if (response.data.success) {
                    this.$noty.success(this.trans('common.success.saving'));
                    this.$router.push({ name: this.modelParams.modelName });
                } else {
                    console.log(response.data);
                    this.$noty.error(response.data.msge);
                }
            } catch (err) {
                if (err.response && err.response.data.error) {
                    this.$noty.error(this.trans('common.' + err.response.data.error));
                } else {
                    console.log('an error occured ' + err);
                    this.$noty.error(this.trans('common.error.saving'));
                }
            }
        },
    }
};

</script>
<style>
/*
Classic editor (CKEditor 5) no longer encapsulates the editing area in an element like CK4, which means that the
height (and similar options) of the editing area must now be controlled with CSS

Warning: do not scope this CSS, it's elements are rendered separately from Vue and no data attributes are added to them
so scoped won't work
*/
.ck-editor__editable {
    min-height: 300px;
}

</style>
