<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon v-if="model=='App\\News'">article</md-icon>
                    <md-icon v-if="model=='App\\Notice'">sticky_note_2</md-icon>
                    <md-icon v-if="model=='App\\Ad'">view_kanban</md-icon>
                </div>
                <h4 class="title">
                    <span v-if="model=='App\\News'">{{ trans('app.news') }}</span>
                    <span v-if="model=='App\\Notice'">{{ trans('app.notices') }}</span>
                    <span v-if="model=='App\\Ad'">{{ trans('app.ads') }}</span>
                    <md-progress-spinner v-if="loading" :md-stroke="1" :md-diameter="20" md-mode="indeterminate"></md-progress-spinner>
                </h4>
            </md-card-header>
            <md-card-content>
                <div class="md-layout" v-if="model=='App\\News'">
                    <div class="md-layout-item md-size-100 label-for-filter">
                        <span>{{trans('common.filter_by_category')}}:</span>
                        <md-button :class="[currentCategory == null ? 'md-primary' : 'md-secondary']" @click="filterCategory(null)" :disabled="loading" id="btnfilterCategoryAll">
                            <span>{{ trans('common.all') }}</span>
                        </md-button>

                        <md-button :class="[currentCategory === category.code ? 'md-primary' : 'md-secondary']" v-for="(category) in categories" :key='category.code' @click="filterCategory(category.code)" :disabled="loading">
                            <span>{{ category.description }}</span>
                        </md-button>
                    </div>
                </div>

                <div class="md-layout" v-if="model=='App\\News'">
                    <div class="md-layout-item md-size-100 label-for-filter">
                        <span>{{trans('common.filter_by_status')}}:</span>
                        <md-button :class="[currentStatus == null ? 'md-primary' : 'md-secondary']" @click="filterStatus(null)" :disabled="loading" id="btnFilterStatusAll">
                            <span>{{ trans('common.all') }}</span>
                        </md-button>

                        <md-button :class="[currentStatus == true ? 'md-primary' : 'md-secondary']" @click="filterStatus(true)" :disabled="loading">
                            <span>{{ trans('app.published') }}</span>
                        </md-button>

                        <md-button :class="[currentStatus == false ? 'md-primary' : 'md-secondary']" @click="filterStatus(false)" :disabled="loading">
                            <span>{{ trans('app.scheduled') }}</span>
                        </md-button>
                    </div>
                </div>

                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-table v-model="items.data" class="paginated-table table-striped table-hover" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">
                            <md-table-toolbar>
                                <div class="md-toolbar-section-start">
                                    <router-link :to="{name: modelParams.routerAddLink}">
                                        <md-button class="btn btn-primary md-primary" id="btnAdd">
                                            <div class="float-left" >
                                                <md-icon>add</md-icon>
                                            </div>
                                            &nbsp; {{ trans('common.add') }}
                                        </md-button>
                                    </router-link>
                                </div>
                                <md-field md-clearable class="md-toolbar-section-end">
                                    <md-input id="txt_search" name="txt_search" :placeholder="trans('common.txt_search')" v-model="txt_search" @input="fetchItems()">
                                    </md-input>
                                </md-field>
                            </md-table-toolbar>

                            <md-table-empty-state
                                :md-label="emptyText">
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item, index }">
                                <md-table-cell :md-label="trans('app.state')" md-sort-by="scheduled">
                                    <md-icon v-if="item.scheduled">hourglass_empty</md-icon>
                                    <md-icon v-else>done</md-icon>
                                    <md-tooltip md-direction="top">{{ item.state }}</md-tooltip>
                                </md-table-cell>
                                <md-table-cell v-if="hasHighlight" :md-label="trans('fields.highlight')" md-sort-by="highlight_end_date">
                                    <md-icon v-if="item.highlight">star</md-icon>
                                    <md-tooltip v-if="item.highlight" md-direction="top">{{ trans('fields.highlight') }}</md-tooltip>
                                </md-table-cell>
                                <md-table-cell v-if="hasCategory" :md-label="trans('fields.type')" md-sort-by="category">{{ item.category.description }}</md-table-cell>
                                <md-table-cell :md-label="trans('fields.date')" md-sort-by="timestamp">{{ item.timestamp }}</md-table-cell>
                                <md-table-cell :md-label="trans('fields.title')" md-sort-by="title">{{ item.title }}</md-table-cell>
                                <md-table-cell :md-label="trans('common.actions')">
                                    <router-link :to="{name: modelParams.routerAddLink, params: { id: item.id }}" :title="trans('common.edit')" id="lnkEdit">
                                        <md-icon class="md-primary">edit</md-icon>
                                    </router-link>
                                    <!--b-link v-if="item.email==currentUser.email" :title="trans('common.forbidden')" id="lnkEdit" disabled>
                                        <md-icon>delete</md-icon>
                                    </b-link-->
                                    <b-link :title="trans('common.delete')" @click="confirmDeleteItems(item, index)" id="lnkDelete">
                                        <md-icon class="md-primary">delete</md-icon>
                                    </b-link>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>

                    <div class="mx-auto" style="margin-top: 18px;">
                        <pagination ref="pag" :data="items" :limit=10 @pagination-change-page="fetchItems"></pagination>
                    </div>
                </div>
            </md-card-content>
        </md-card>

        <md-dialog-confirm
            :md-active.sync="showConfirmDelete"
            :md-title="trans('common.confirmation')"
            :md-content="confirmDeleteContent"
            :md-confirm-text="trans('common.ok')"
            :md-cancel-text="trans('common.cancel')"
            @md-confirm="deleteItems" />
    </div>
</template>
<script>
import tableEmptyText from "../../../mixins/tableEmptyText";
import {api} from "../../../config";
import {mapState} from 'vuex';

export default {
    mixins: [
        tableEmptyText,
    ],

    data() {
        return {
            items: {
                current_page:1,
                data:[]
            },
            selectedItems: {},
            selectedIndex: 0,
            txt_search: "",
            showConfirmDelete: false,
            confirmDeleteContent: this.trans('app.confirm.delete', {user: ''}),
            currentSort: 'timestamp',
            currentSortOrder: 'desc',
            emptyText: ' ', // Space needed! :?
            loading: false,
            currentCategory: null,
            categories: [],
            currentStatus: null,
        }
    },
    computed: {
        mapState: function(){currentUser: state => state.auth},

        hasImage: function() {
            return (this.model == 'App\\News' || this.model == 'App\\Ad');
        },

        hasCategory: function() {
            return (this.model == 'App\\News');
        },

        hasHighlight: function() {
            return (this.model == 'App\\News' || this.model == 'App\\Ad');
        },

        modelParams: function(){
            let routeAddLink = '';
            let fetchLink = '';
            let modelTranslated = '';
            let modelName = '';
            let selectedCategory = '';
            let selectedStatus = '';
            switch(this.model) {
                case 'App\\News':
                    routeAddLink = 'newsItem';
                    fetchLink = 'news-list';
                    modelTranslated = this.trans('app.news');
                    modelName = 'news';
                    selectedCategory = 'news' + '_' + 'currentCategory';
                    selectedStatus = 'news' + '_' + 'currentStatus';
                    break;
                case 'App\\Notice':
                    routeAddLink = 'noticesItem';
                    fetchLink = 'notices-list';
                    modelTranslated = this.trans('app.notices');
                    modelName = 'notices';
                    selectedCategory = 'notices' + '_' + 'currentCategory';
                    selectedStatus = 'notices' + '_' + 'currentStatus';
                    break;
                case 'App\\Ad':
                    routeAddLink = 'adsItem';
                    fetchLink = 'ads-list';
                    modelTranslated = this.trans('app.ads');
                    modelName = 'ads';
                    selectedCategory = 'ads' + '_' + 'currentCategory';
                    selectedStatus = 'ads' + '_' + 'currentStatus';
                    break;
            }

            return {
                routerAddLink: routeAddLink,
                fetchLink: fetchLink,
                modelTranslated: modelTranslated,
                modelName: modelName,
                selectedCategory: selectedCategory,
                selectedStatus: selectedStatus,
            };
        }
    },

    watch: {
      selectedItems: function (items) {
        this.confirmDeleteContent =  this.trans('app.confirm.delete_' + this.modelParams.modelName, {items: this.selectedItems.title});
      },

      // To keep the filtering status between screens
      currentCategory: function () {
        if(this.currentCategory != 'null')
            localStorage.setItem(this.modelParams.selectedCategory, this.currentCategory);
        else
            localStorage.removeItem(this.modelParams.selectedCategory);
      },
      currentStatus: function () {
        if(this.currentStatus != 'null')
            localStorage.setItem(this.modelParams.selectedStatus, this.currentStatus);
        else
            localStorage.removeItem(this.modelParams.selectedStatus);
      }
    },

    props: {
        model: {
            type: String,
            required: true,
        }
    },

    mounted() {
        this.emptyText = this.trans('app.no_results', {item: this.modelParams.modelTranslated});

        // To keep the filtering status between screens
        if(localStorage.getItem(this.modelParams.selectedCategory) && localStorage.getItem(this.modelParams.selectedCategory) != 'null') {
            this.filterCategory(localStorage.getItem(this.modelParams.selectedCategory));
        }
        if(localStorage.getItem(this.modelParams.selectedStatus) && localStorage.getItem(this.modelParams.selectedStatus) != 'null') {
            this.filterStatus(localStorage.getItem(this.modelParams.selectedStatus) === 'true');
        }
    },

    methods: {

        async filterCategory(category=null) {
            this.currentCategory = category;
            this.fetchItems();
        },

        async filterStatus(status=null) {
            this.currentStatus = status;
            this.fetchItems();
        },

        async fetchItems(page=1){
            this.loading = true;
            axios.post(api.domain + this.modelParams.fetchLink + '?page='+page, {txt_search: this.txt_search, sort_field: this.currentSort, order: this.currentSortOrder, category: this.currentCategory, status: this.currentStatus})
            .then((response) => {
                this.items = response.data[this.modelParams.modelName];
                if(response.data.categories) this.categories = response.data.categories;
                this.loading = false;
            })
            .catch((error) => {
                console.log('An error ocurred ' + error);
                this.loading = false;
            });
        },

        clearFilters(){
            this.txt_search="";
            this.fetchItems();
        },

        confirmDeleteItems(user, key) {
            this.selectedItems = user;
            this.selectedIndex = key;
            this.showConfirmDelete = true;
        },

        deleteItems() {
            let id = this.selectedItems.id;
            let key = this.selectedIndex;
            let uri = api.domain + this.modelParams.modelName + '/' + id;
            console.log(uri);
            this.loading = true;
            axios.delete(uri).then((response) => {
                this.items.data.splice(key, 1);
                if (this.items.data.length == 0) {
                    this.fetchItems(this.$refs.pag.data.current_page - 1);
                }
                this.loading = false;
            }).catch(error => {
                // handle error
                this.loading = false;
                if (error.response.status == 400) {
                    console.log('an error occured ' + error.response.data.message);
                } else {
                    console.log('an error occured ' + error);
                }
            });
        },

        customSort: function customSort(value) {
            this.fetchItems();
            return value;
        }
    }
}
</script>
