<template>
  <div class="md-layout-item md-size-30 md-xsmall-size-100">
        <h4 class="card-title">{{ titleImage }}</h4>
        <div class="file-input">
          <div v-if="height===''" class="image-container">
            <img v-if="!image || image==''" :src="regularImg" :title="titleImage" :style='imgStyle'/>
            <img v-else :src="image" :title="titleImage" :style='imgStyle'/>
          </div>
          <div v-else class="image-container" :style='heightStyle'>
            <img v-if="!image || image==''" :src="regularImg" :title="titleImage" :style='imgStyle'/>
            <img v-else :src="image" :title="titleImage" :style='imgStyle'/>
          </div>
          <div class="button-container">
                <md-button
                  class="md-danger md-just-icon md-round"
                  @click="removeImage"
                  v-if="image"
                  >
                  <md-icon>delete_forever</md-icon>
                  <md-tooltip md-direction="top">
                    {{ trans('common.remove') }}
                  </md-tooltip>
                </md-button
                >
                <md-button class="md-primary md-just-icon md-round md-fileinput">
                  <template v-if="!image">
                    <md-icon>image_search</md-icon>
                    <md-tooltip md-direction="top">
                      {{ trans('common.select_image') }}
                    </md-tooltip>
                  </template>
                  <template v-else>
                    <md-icon class="md-primary">image_search</md-icon>
                    <md-tooltip md-direction="top">
                      {{ trans('common.change') }}
                    </md-tooltip>
                  </template>
                  <input type="file" @change="onFileChange" />
                </md-button>
            </div>
        </div>
    </div>  
</template>

<script>
export default {
    props: {
      default: {
        type: String,
        default: '/img/image_placeholder.jpg'
      },    
      title: {
        type: String,
        default: ''
      },
      img: { 
        type: String,
        default: ''
      },
      height: {
        type: String,
        default: ''
      },
      model: {
        type: Object
      },
      field: {
        type: String
      }
    },

    data() {
      return {
        regularImg: this.default,
        titleImage: this.title,
        image: this.img,
        imageModel: this.model,
        heightStyle: 'height:'+this.height+';',
        imgStyle: 'height:'+this.height+'; width:auto;',
      }
    },

    watch: {
      default: function(newDefault) {
        this.regularImg = newDefault;
      },
      title: function(newTitle) {
        this.titleImage = newTitle;
      },
      img: function(newImage) {
        this.image = newImage;
      },
      model: function(newModel) {
        this.imageModel = newModel;
      }
    },

    methods: {
      createImage(file, type) {
        let reader = new FileReader();

        reader.onload = e => {
          this.image = e.target.result;
        };

        reader.readAsDataURL(file);
      },

      removeImage: function(type) {
        this.image = "";
        this.$emit('change', this.imageModel, this.field+'_s3_key', '');
      },

      onFileChange(e) {    
        let files = e.target.files || e.dataTransfer.files;
        if (files.length) {
          this.createImage(files[0]);
          this.$emit('change', this.imageModel, this.field+'_file', files[0]);    
        }
      },      
    } 
}
</script>