<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute
    }"
  >
    <div class="md-toolbar-row">
      <md-button v-if="$route.name != 'dashboard'" @click="back()" class="float-md-left md-icon-button md-primary" id="btnBack">
        <md-icon>arrow_back</md-icon>
      </md-button>
      <div class="md-toolbar-section-start">
        <h3 class="md-title" style="margin: 0px 15px; color: #757575;">{{trans('routes.'+$route.name)}}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <!--div class="md-autocomplete">
            <md-autocomplete class="search" v-model="selectedEmployee" :md-options="employees">
              <label>Search...</label>
            </md-autocomplete>
          </div-->
          <md-list>

            <md-list-item>
                <router-link :to="{name: 'profile'}" :title="trans('common.edit')" id="lnkEdit">
                    <md-icon class="md-primary">person</md-icon>
                    {{ currentUser.email }}
                </router-link>
              <p class="hidden-lg hidden-md">{{ trans('common.user') }}</p>
            </md-list-item>
            <md-list-item to="/">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">{{ trans('common.dashboard') }}</p>
            </md-list-item>
            <md-list-item to="/logout">
              <i class="material-icons">power_settings_new</i>
              <p class="hidden-lg hidden-md">{{ trans('common.logout') }}</p>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>

  </md-toolbar>
</template>

<script>
import {mapState} from 'vuex'

export default{
  data () {
    return {
    }
  },
  computed: mapState({
    currentUser: state => state.auth
  }),
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    back() {
      this.$router.back();
    }
  }
}
</script>

<style lang="css">
</style>
