<template>
    <div class="md-layout-item">
        <h4>{{ trans('fields.filter_by_date') }}:</h4>

        <form class="md-layout" v-on:submit.prevent>
                <div class="md-layout-item md-size-25 md-small-size-100">
                    <md-field :class="form.getFieldClass('date')">
                        <label for="date_from">{{ trans('fields.date_from')+" (dd/mm/aaaa)" }}</label>
                        <md-input id="date_from" v-model="form.date_from"/>
                        <has-error :form="form" field="date_from"/>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100">
                    <md-field :class="form.getFieldClass('date')">
                        <label for="date_to">{{ trans('fields.date_to')+" (dd/mm/aaaa)" }}</label>
                        <md-input id="date_to" v-model="form.date_to"/>
                        <has-error :form="form" field="date_to"/>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100 text-left">
                    <md-button class="md-primary" @click="fetchUserStats()" :disabled="form.loading" id="btnSave">
                        <span v-show="form.loading">{{ trans('common.filtering') }}</span>
                        <span v-show="!form.loading">{{ trans('common.filter') }}</span>
                    </md-button>
                </div>
        </form>

        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>person</md-icon>
                </div>
                <h4 class="title">
                    {{ trans('app.users') }}
                    <md-progress-spinner v-if="loading" :md-stroke="1" :md-diameter="20" md-mode="indeterminate"></md-progress-spinner>
                </h4>
            </md-card-header>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-table class="paginated-table table-striped table-hover">

                            <md-table-empty-state
                                :md-label="emptyText">
                            </md-table-empty-state>

                            <md-table-row v-for="item in computedRows" :key="item.id">
                                <md-table-cell>{{ item.description }}</md-table-cell>
                                <md-table-cell>
                                    <div class="stats-bg-percent" :style="{'width':item.percent}">
                                        <div>{{ item.users_count }}</div>
                                    </div>
                                </md-table-cell>
                            </md-table-row>
                            </md-table>
                    </div>

                </div>
            </md-card-content>
        </md-card>

        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>computer</md-icon>
                </div>
                <h4 class="title">
                    {{ trans('app.devices') }}
                    <md-progress-spinner v-if="loading" :md-stroke="1" :md-diameter="20" md-mode="indeterminate"></md-progress-spinner>
                </h4>
            </md-card-header>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-table v-model="devices.data" class="paginated-table table-striped table-hover">

                            <md-table-empty-state
                                :md-label="emptyText">
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell>{{ item.name }}</md-table-cell>
                                <md-table-cell>
                                    <div class="stats-bg-percent" :style="{'width':item.percent}">
                                        <div>{{ item.percent }}</div>
                                    </div>
                                </md-table-cell>
                            </md-table-row>
                            </md-table>
                    </div>

                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import tableEmptyText from "../../../mixins/tableEmptyText";
import {api} from "../../../config";
import Form from "../../../plugins/Form/Form";
import {mapState} from 'vuex';

export default {
    mixins: [
        tableEmptyText,
    ],

    data() {
        return {
            roles: {
                data:[],
                total: 0,
            },
            devices: {
                data: [],
            },
            loading: false,
            emptyText: this.trans('app.no_results', {item: this.trans('routes.stats')}),
            form: new Form(),
        }
    },

    computed: {
        computedRows() {
            // Combine the hardcoded row and the data from v-model
            const totalRow = { id: '0', description: 'Total', percent: '100%', users_count: this.roles.total };
            return [totalRow, ...this.roles.data];
        }
    },

    mounted() {
            this.fetchUserStats();
            //this.fetchFormData();
        },

    methods: {
        async fetchUserStats(){
            var response;

            try {

                response = await this.form.post(api.domain + 'stats');

                if (response.data.success) {
                    this.roles.data = response.data.roles;
                    this.roles.total = response.data.total;
                    this.devices.data = response.data.devices;
                } else {
                    this.$noty.error(response.data.msge);
                }
            } catch(err) {
                if (err.response && err.response.data.error) {
                    this.$noty.error(this.trans('common.'+err.response.data.error));
                } else {
                    console.log('an error occured ' + err);
                    this.$noty.error(this.trans('common.error.saving'));
                }
            }
        },


    }
}
</script>
