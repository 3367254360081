<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>person</md-icon>
                </div>
                <h4 class="title">{{ trans('app.user') }}</h4>
            </md-card-header>
            <md-card-content>
                <form class="md-layout" v-on:submit.prevent>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('name')">
                            <label for="name">{{ trans('fields.name') }}</label>
                            <md-input id="name" v-model="user.name"/>
                            <has-error :form="user" field="name"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('email')">
                            <label for="email">{{ trans('fields.email') }}</label>
                            <md-input type="email" id="email" v-model="user.email"/>
                            <has-error :form="user" field="email"/>
                        </md-field>
                    </div>



                    <div v-if="user.id" class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('password')">
                            <label for="password">{{ trans('fields.password') }}</label>
                            <md-input type="password" id="password" v-model="user.password"/>
                            <has-error :form="user" field="password"/>
                        </md-field>
                    </div>
                    <div v-else class="md-layout-item md-size-50 md-small-size=0">
                        &nbsp;
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('password_confirmation')">
                            <label for="password_confirmation">{{ trans('fields.password_confirmation') }}</label>
                            <md-input type="password" id="password_confirmation" v-model="user.password_confirmation"/>
                            <has-error :form="user" field="password_confirmation"/>
                        </md-field>
                    </div>


                    <div class="md-layout-item md-size-100 text-right">
                        <md-button class="md-primary" @click="save()" :disabled="user.loading" id="btnSave">
                            <span v-show="user.loading">{{ trans('common.saving') }}</span>
                            <span v-show="!user.loading">{{ trans('common.save') }}</span>
                        </md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
    import { api } from "../../../config";
    import Form from "../../../plugins/Form/Form";

    export default {
        mounted() {
            this.fetchData();
        },

        data() {
            return {
                requiredFields: [
                    "name",
                    "email",
                ],
                user: new Form(),
                loading: false,
            };
        },

        computed: {
        },

        methods: {

            fetchData() {
                var url = api.domain + "profile";

                axios.get(url).then(response => {
                    this.user = new Form(response.data.user);
                    this.user.roles = response.data.user.selected_roles;
                }) .catch(function(error) {
                    console.log("An error occured obtaining user info" + error);
                });
            },

            async save() {
                var response;

                try {
                    response = await this.user.put(api.domain + 'profile');

                    if (response.data.success) {
                        this.$noty.success(this.trans('common.success.saving'));
                    } else {
                        console.log(response.data);
                        this.$noty.error(response.data.msge);
                    }
                } catch(err) {
                    if (err.response && err.response.data.error) {
                        this.$noty.error(this.trans('common.'+err.response.data.error));
                    } else {
                        console.log('an error occured ' + err);
                        this.$noty.error(this.trans('common.error.saving'));
                    }
                }
            },
        }
    };
</script>
