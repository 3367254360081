<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>newspaper</md-icon>
                </div>
                <h4 class="title">{{ trans('app.news_item') }}</h4>
            </md-card-header>
            <md-card-content>
                <form class="md-layout" v-on:submit.prevent>
                    <div class="md-layout-item md-size-100">
                        <div class="md-layout">
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <regular-image ref='image' @change="onFileSelection" :model='form' field='image' :img='form.image' height='80px'></regular-image>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <div class="md-layout-item md-size-100">
                                    <md-field :class="form.getFieldClass('date')">
                                        <label for="date">{{ trans('fields.date')+" (dd/mm/aaaa)" }}</label>
                                        <md-input id="date" v-model="form.date"/>
                                        <has-error :form="form" field="date"/>
                                    </md-field>
                                </div>
                                <div class="md-layout-item md-size-100">
                                    <md-field :class="form.getFieldClass('hour')">
                                        <label for="hour">{{ trans('fields.hour')+" (HH:mm)" }}</label>
                                        <md-input id="hour" v-model="form.hour"/>
                                        <has-error :form="form" field="hour"/>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('title')">
                            <label for="title">{{ trans('fields.title') }}</label>
                            <md-input id="title" v-model="form.title"/>
                            <has-error :form="form" field="title"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('description')">
                            <label for="description">{{ trans('fields.description') }}</label>
                            <md-textarea id="description" v-model="form.description"/>
                            <has-error :form="form" field="description"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('category_code')">
                            <label for="category_code">{{ trans('fields.category_code') }}</label>
                            <md-select id='category_code' v-model='form.category_code' style="overflow: hidden;">
                                <md-option v-for="(category, index) in categories" :key='index' :value='category.code' >{{ category.description }}</md-option>
                            </md-select>
                            <has-error :form="form" field="category_code"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('content')">
                            <label for="content">{{ trans('fields.content') }}</label>
                            <md-textarea id="content" v-model="form.content"/>
                            <has-error :form="form" field="content"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <label for="roles">{{ trans('app.news_roles') }}</label>
                    </div>
                    <div class="md-layout-item md-size-20 md-small-size-100">
                        <md-checkbox v-model="form.public" :value="true" @change="checkPublic">{{ trans('app.public') }}</md-checkbox>
                    </div>
                    <div class="md-layout-item md-size-80 md-small-size-100">
                        <md-field :class="form.getFieldClass('roles')">
                            <label for="roles">{{ trans('fields.roles') }}</label>
                            <md-select id='roles' v-model='form.roles' multiple :disabled="form.public" style="overflow: hidden;">
                                <md-option v-for="(role, index) in roles" :key='index' :value='role.id' >{{ role.description }}</md-option>
                            </md-select>
                            <has-error :form="form" field="roles"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <label for="highlight">{{ trans('fields.highlight') }}</label>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-radio v-model="form.highlight" :value="true" class="md-primary">{{ trans('common.yes') }}</md-radio>
                        <md-radio v-model="form.highlight" :value="false" class="md-primary">{{ trans('common.no') }}</md-radio>
                        <has-error :form="form" field="highlight"/>
                    </div>
                    <div v-if="form.highlight" class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('highlight_end_date')">
                            <label for="highlight_end_date">{{ trans('fields.highlight_end_date')+" (dd/mm/aaaa)" }}</label>
                            <md-input id="highlight_end_date" v-model="form.highlight_end_date" />
                            <has-error :form="form" field="highlight_end_date"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <label for="pinned">{{ trans('fields.pinned') }}</label>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-radio v-model="form.pinned" :value="true" class="md-primary">{{ trans('common.yes') }}</md-radio>
                        <md-radio v-model="form.pinned" :value="false" class="md-primary">{{ trans('common.no') }}</md-radio>
                        <has-error :form="form" field="pinned"/>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <label for="notification">{{ trans('app.send_notification') }}</label>
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-radio v-model="form.notification" :value="true" class="md-primary">{{ trans('common.yes') }}</md-radio>
                        <md-radio v-model="form.notification" :value="false" class="md-primary">{{ trans('common.no') }}</md-radio>
                        <has-error :form="form" field="notification"/>
                    </div>
                    <div v-if="form.notification" class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('notification_type_code')">
                            <label for="notification_type_code">{{ trans('fields.notification_type_code') }}</label>
                            <md-select id='notification_type_code' v-model='form.notification_type_code' style="overflow: hidden;">
                                <md-option v-for="(type, index) in types" :key='index' :value='type.code' >{{ type.description }}</md-option>
                            </md-select>
                            <has-error :form="form" field="notification_type_code"/>
                        </md-field>
                    </div>
                    <div v-if="form.notification && (form.notification_type_code=='email' || form.notification_type_code=='both')" class="md-layout-item md-size-100">
                        <md-field :class="form.getFieldClass('notification_url')">
                            <label for="notification_url">{{ trans('fields.notification_url') }}</label>
                            <md-input id="notification_url" v-model="form.notification_url"/>
                            <has-error :form="form" field="notification_url"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100 text-right">
                        <md-button class="md-primary" @click="add()" :disabled="form.loading" id="btnSave">
                            <span v-show="form.loading">{{ trans('common.saving') }}</span>
                            <span v-show="!form.loading">{{ trans('common.save') }}</span>
                        </md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
    import { api } from "../../../config";
    import Form from "../../../plugins/Form/Form";
    import file from "../../../mixins/file";
    import RegularImage from "../../Inputs/RegularImage.vue";

    export default {
        mixins: [
            file,
        ],

        components: {
            RegularImage
        },

        mounted() {
            this.fetchData();
        },

        data() {
            return {
                requiredFields: [
                    "title",
                    "description",
                    "category_code",
                    "content",
                ],
                form: new Form(),
                categories: [],
                roles: [],
                types: [],
            };
        },

        methods: {
            checkPublic(value) {
                if (value) {
                    this.form.roles = [];
                }
            },

            fetchData() {
                var url = api.domain + "news/";
                if (this.$route.params.id) {
                    url += this.$route.params.id + "/edit";
                } else {
                    url += "create";
                }
                axios.get(url).then(response => {
                    this.form = new Form(response.data.news);
                    if (response.data.news.roles) {
                        this.form.roles = response.data.news.roles.map((element) => element.id);
                    } else {
                        this.form.roles = [];
                    }
                    this.form.requiredFields = this.requiredFields;
                    this.categories = response.data.categories;
                    this.roles = response.data.roles;
                    this.types = response.data.types;
                }) .catch(function(error) {
                    console.log("An error occured obtaining news info" + error);
                });
            },

            async add() {
                var response;

                try {
                    if (this.form.id != null && this.form.id != '') {
                        // Edition
                        response = await this.form.put(api.domain + 'news/'+this.form.id);
                    } else {
                        // Creation
                        response = await this.form.post(api.domain + 'news');
                    }

                    if (response.data.success) {
                        this.$noty.success(this.trans('common.success.saving'));
                        this.$router.push({ name: 'news'});
                    } else {
                        this.$noty.error(response.data.msge);
                    }
                } catch(err) {
                    if (err.response && err.response.data.error) {
                        this.$noty.error(this.trans('common.'+err.response.data.error));
                    } else {
                        console.log('an error occured ' + err);
                        this.$noty.error(this.trans('common.error.saving'));
                    }
                }
            },
        }
    };
</script>
