<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>person</md-icon>
                </div>
                <h4 class="title">{{ trans('app.user') }}</h4>
            </md-card-header>
            <md-card-content>
                <form class="md-layout" v-on:submit.prevent>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('name')">
                            <label for="name">{{ trans('fields.name') }}</label>
                            <md-input id="name" v-model="user.name"/>
                            <has-error :form="user" field="name"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('email')">
                            <label for="email">{{ trans('fields.email') }}</label>
                            <md-input type="email" id="email" v-model="user.email"/>
                            <has-error :form="user" field="email"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('roles')">
                            <label for="roles">{{ trans('fields.roles') }}</label>
                            <md-select id='roles' v-model='user.roles' multiple style="overflow: hidden;">
                                <md-option v-for="(role, index) in roles" :key='index' :value='role.id' >{{ role.description }}</md-option>
                            </md-select>
                            <has-error :form="user" field="roles"/>
                        </md-field>
                    </div>

                    <div v-if="user.id" class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('password')">
                            <label for="password">{{ trans('fields.password') }}</label>
                            <md-input type="password" id="password" v-model="user.password"/>
                            <has-error :form="user" field="password"/>
                        </md-field>
                    </div>
                    <div v-else class="md-layout-item md-size-50 md-small-size=0">
                        &nbsp;
                    </div>
                    <!--div class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('password_confirmation')">
                            <label for="password_confirmation">{{ trans('fields.password_confirmation') }}</label>
                            <md-input type="password" id="password_confirmation" v-model="user.password_confirmation"/>
                            <has-error :form="user" field="password_confirmation"/>
                        </md-field>
                    </div-->

                    <div v-if="isEmpleado" class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('department')">
                            <label for="department">{{ trans('fields.department') }}</label>
                            <md-input id="department" v-model="user.department"/>
                            <has-error :form="user" field="department"/>
                        </md-field>
                    </div>

                    <div v-if="isEmpleado" class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('position')">
                            <label for="position">{{ trans('fields.position') }}</label>
                            <md-input id="position" v-model="user.position"/>
                            <has-error :form="user" field="position"/>
                        </md-field>
                    </div>

                    <div v-if="isEmpleado" class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('phone')">
                            <label for="phone">{{ trans('fields.phone') }}</label>
                            <md-input id="phone" v-model="user.phone"/>
                            <has-error :form="user" field="phone"/>
                        </md-field>
                    </div>

                    <div v-if="isEmpleado" class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('extension')">
                            <label for="extension">{{ trans('fields.extension') }}</label>
                            <md-input id="extension" v-model="user.extension"/>
                            <has-error :form="user" field="extension"/>
                        </md-field>
                    </div>

                    <div v-if="isEmpleado" class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('birthdate')">
                            <label for="birthdate">{{ trans('fields.birthdate') }}({{ trans('app.birthdate_format') }})</label>
                            <md-input id="birthdate" v-model="user.birthdate"/>
                            <has-error :form="user" field="birthdate"/>
                        </md-field>

                        <!--md-datepicker v-model="user.birthdate" md-immediately :class="user.getFieldClass('birthdate')">
                            <label for="birthdate">{{ trans('fields.birthdate') }}({{ trans('app.birthdate_format') }})</label>
                            <has-error :form="user" field="birthdate"/>
                        </md-datepicker-->
                    </div>
                    <div v-if="isEmpleado" class="md-layout-item md-size-50 md-small-size-0">
                        &nbsp;
                    </div>

                    <div v-if="isSocio || isEmpleado" class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('discount')">
                            <label for="discount">{{ trans('fields.discount') }}</label>
                            <md-input id="discount" v-model="user.discount"/>
                            <has-error :form="user" field="discount"/>
                        </md-field>
                    </div>

                    <div v-if="isSocio" class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('membership_number')">
                            <label for="membership_number">{{ trans('fields.membership_number') }}</label>
                            <md-input id="membership_number" v-model="user.membership_number"/>
                            <has-error :form="user" field="membership_number"/>
                        </md-field>
                    </div>

                    <div v-if="user.id" class="md-layout-item md-size-50 md-small-size-100">
                        <!--md-checkbox v-model="user.verified" id="chkVerified">{{ trans('app.verified') }}</md-checkbox-->
                        <md-radio v-model="user.verified" :value="true" class="md-primary">{{trans('app.verified')}}</md-radio>
                        <md-radio v-model="user.verified" :value="false" class="md-primary">{{trans('app.pending')}}</md-radio>
                    </div>

                    <div class="md-layout-item md-size-100 text-right">
                        <md-button class="md-primary" @click="add()" :disabled="user.loading" id="btnSave">
                            <span v-show="user.loading">{{ trans('common.saving') }}</span>
                            <span v-show="!user.loading">{{ trans('common.save') }}</span>
                        </md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
    import { api } from "../../../config";
    import Form from "../../../plugins/Form/Form";

    export default {
        mounted() {
            this.fetchData();
        },

        data() {
            return {
                requiredFields: [
                    "name",
                    "email",
                    "roles",
                    "department",
                    "position",
                    "phone",
                    "extension",
                    "birthdate",
                    "discount",
                    "membership_number"
                    //"password"
                ],
                user: new Form(),
                roles: [],
                currentSort: 'name',
                currentSortOrder: 'asc',
                loading: false,
                txt_search: "",
            };
        },

        computed: {
            isEmpleado: function () {
                return this.checkRole('empleado');
            },
            isSocio: function () {
                return this.checkRole('socio');
            }
        },

        methods: {
            checkRole(role) {
                var res = this.roles.filter(item=> item.name == role && this.user.roles.includes(item.id));
                return res.length>0;
            },

            fetchData() {
                var url = api.domain + "user/";
                if (this.$route.params.id) {
                    url += this.$route.params.id + "/edit";
                } else {
                    url += "create";
                }
                axios.get(url).then(response => {
                    this.user = new Form(response.data.user);
                    this.user.roles = response.data.user.selected_roles;
                    this.user.requiredFields = this.requiredFields;
                    this.roles = response.data.roles;
                }) .catch(function(error) {
                    console.log("An error occured obtaining user info" + error);
                });
            },

            async add() {
                var response;

                try {
                    if (this.user.id != null && this.user.id != '') {
                        // Edition
                        response = await this.user.put(api.domain + 'user/'+this.user.id);
                    } else {
                        // Creation
                        response = await this.user.post(api.domain + 'user');
                    }

                    if (response.data.success) {
                        this.$noty.success(this.trans('common.success.saving'));
                        this.$router.push({ name: 'users'});
                    } else {
                        this.$noty.error(response.data.msge);
                    }
                } catch(err) {
                    if (err.response && err.response.data.error) {
                        this.$noty.error(this.trans('common.'+err.response.data.error));
                    } else {
                        console.log('an error occured ' + err);
                        this.$noty.error(this.trans('common.error.saving'));
                    }
                }
            },
        }
    };
</script>
